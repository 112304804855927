import { PageWithIntro, WizardLayout } from 'components';
import { MainPage } from './_MainPage';
import React from 'react';
import { roomsStep } from '../../../utilities/stepDefinitions';
import { useTracking } from '../../../utilities/hooks';

const RoomsPage = props => {
  useTracking('Rooms');

  return (
    <PageWithIntro step={roomsStep}>
      <WizardLayout text>
        <MainPage {...props} />
      </WizardLayout>
    </PageWithIntro>
  );
};

export default RoomsPage;

import { Button, Divider, Grid } from 'semantic-ui-react';
import React, { Fragment } from 'react';

import NextStepButton from 'components/widgets/NextStepButton';
import RoomList from 'components/widgets/RoomList';

const LicenseSection = ({
  license,
  plantRooms,
  inventoryRooms,
  onEditPlantRoom,
  onEditInventoryRoom,
  onAddPlantRoom,
  onAddInventoryRoom,
  skippablePlantSectionBuilder,
  skippableInventorySectionBuilder,
}) => (
  <Fragment>
    <h2>Rooms for License #{license}</h2>

    <Grid columns="2">
      <Grid.Row>
        <Grid.Column width={8}>
          {skippablePlantSectionBuilder(
            <Fragment>
              <RoomList title="Plant Rooms" rooms={plantRooms} onEdit={onEditPlantRoom} />
              <Button onClick={() => onAddPlantRoom(license)}>+ Add Plant Room</Button>
            </Fragment>
          )}
        </Grid.Column>

        <Grid.Column width={8}>
          {skippableInventorySectionBuilder(
            <Fragment>
              <RoomList
                title="Inventory Rooms"
                rooms={inventoryRooms}
                onEdit={onEditInventoryRoom}
              />
              <Button onClick={() => onAddInventoryRoom(license)}>
                + Add Inventory Room
              </Button>
            </Fragment>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider />
  </Fragment>
);

export const RoomListingView = ({
  licenses,
  roomsHaveBeenSkippedForAllLicenses,
  skippableSectionsBuilders,
  allPlantRoomsForLicense,
  allInventoryRoomsForLicense,
  onEditPlantRoom,
  onEditInventoryRoom,
  onAddPlantRoom,
  onAddInventoryRoom,
  nextStep,
  stepCompleted,
}) => (
  <Fragment>
    {licenses.map(license => (
      <LicenseSection
        license={license}
        plantRooms={allPlantRoomsForLicense(license)}
        inventoryRooms={allInventoryRoomsForLicense(license)}
        onEditPlantRoom={onEditPlantRoom}
        onEditInventoryRoom={onEditInventoryRoom}
        onAddPlantRoom={onAddPlantRoom}
        onAddInventoryRoom={onAddInventoryRoom}
        skippablePlantSectionBuilder={skippableSectionsBuilders(license).plantRooms}
        skippableInventorySectionBuilder={
          skippableSectionsBuilders(license).inventoryRooms
        }
      />
    ))}

    <NextStepButton
      isSkippingStep={roomsHaveBeenSkippedForAllLicenses}
      nextStep={nextStep}
      stepCompleted={stepCompleted}
    />
  </Fragment>
);

import {
  ModalAddInventoryRoomForm,
  ModalAddPlantRoomForm,
  ModalEditInventoryRoomForm,
  ModalEditPlantRoomForm,
} from 'components/Forms/RoomForms';
import React, { useState } from 'react';
import {
  allState as allRoomsState,
  createQueriesHook as createRoomQueries,
  createSaveInventoryRoomHandler,
  createSavePlantRoomHandler,
  validateInventoryRoom,
  validatePlantRoom,
} from '../../../utilities/rooms';

import {
  allState as allSkipState,
  createSkippableSectionBuilderForKey,
  skipInventoryRoomsKeyBuilder,
  skipPlantRoomsKeyBuilder,
} from '../../../utilities/skippedEntries';

import { InternalStepProgress } from 'components';

import { RoomListingView } from './_RoomListingView';
import { RoomsByLicenseWizardView } from './_RoomsByLicenseWizardView';
import { WistiaVideo } from 'components/widgets/WistiaVideo';
import YesNoModal from 'components/widgets/YesNoModal';
import { createQueriesHook as createAllQueries } from '../../../utilities/allQueries';
import { deleteFormElement } from '../../../utilities/formstate';
import { displayMessage } from 'src/toasts';
import { getLicenses } from '../../../utilities/licenses';
import { roomsStep } from '../../../utilities/stepDefinitions';
import { stepIsComplete } from '../../../utilities/steps';
import { validId } from '../../../utilities/matchers';

export const MainPage = props => {
  const { inventoryRoomsState, plantRoomsState } = allRoomsState();
  const { licenses } = getLicenses();
  const stepCompleted = stepIsComplete(roomsStep);
  const { skipState } = allSkipState();
  const allQueries = createAllQueries();
  const { roomsHaveBeenSkippedForAllLicenses } = allQueries;

  const [inventoryRoomIdBeingEdited, setInventoryRoomIdBeingEdited] = useState('');
  const [plantRoomIdBeingEdited, setPlantRoomIdBeingEdited] = useState('');

  const [showPlantRoomDeleteModal, setShowPlantRoomDeleteModal] = useState(false);
  const [showInventoryRoomDeleteModal, setShowInventoryRoomDeleteModal] = useState(false);

  const [showPlantRoomAddModal, setShowPlantRoomAddModal] = useState(false);
  const [showInventoryRoomAddModal, setShowInventoryRoomAddModal] = useState(false);
  const [licenseToAddTo, setLicenseToAddTo] = useState('');

  const skippableSectionsBuilders = license => ({
    inventoryRooms: createSkippableSectionBuilderForKey(
      {
        label: 'I have no inventory rooms',
        keyBuilder: skipInventoryRoomsKeyBuilder,
        skipState,
        canSkip: allQueries.canSkipInventoryRoomsForLicense(license),
      },
      license
    ),

    plantRooms: createSkippableSectionBuilderForKey(
      {
        label: 'I have no plant rooms',
        keyBuilder: skipPlantRoomsKeyBuilder,
        skipState,
        canSkip: allQueries.canSkipPlantRoomsForLicense(license),
      },
      license
    ),
  });

  const hideDeletionModal = () => {
    setShowPlantRoomDeleteModal(false);
    setShowInventoryRoomDeleteModal(false);
  };

  const hideAddModal = () => {
    setShowPlantRoomAddModal(false);
    setShowInventoryRoomAddModal(false);
  };

  const onModalClose = () => {
    setInventoryRoomIdBeingEdited('');
    setPlantRoomIdBeingEdited('');
    hideDeletionModal();
    hideAddModal();
    setLicenseToAddTo('');
  };

  const plantRoomSaveHandler = createSavePlantRoomHandler({
    inventoryRoomsState,
    plantRoomsState,
    completion: onModalClose,
  });

  const inventoryRoomSaveHandler = createSaveInventoryRoomHandler({
    inventoryRoomsState,
    plantRoomsState,
    completion: onModalClose,
  });

  const roomQueries = createRoomQueries({
    inventoryRoomsState,
    plantRoomsState,
  });

  const cannotDelete = roomId => {
    const canDelete = allQueries.canDeleteRoom(roomId);
    if (canDelete) return false;

    displayMessage('Room cannot be deleted as it is in use by a plant or plant group');
    onModalClose();
    return true;
  };

  const deletePlantRoom = () => {
    if (cannotDelete(plantRoomIdBeingEdited)) return;
    deleteFormElement(plantRoomsState, plantRoomIdBeingEdited);
    onModalClose();
  };

  const deleteInventoryRoom = () => {
    if (cannotDelete(inventoryRoomIdBeingEdited)) return;
    deleteFormElement(inventoryRoomsState, inventoryRoomIdBeingEdited);
    onModalClose();
  };

  const promptForPlantRoomDeletion = () => {
    setShowPlantRoomDeleteModal(true);
  };

  const promptForInventoryRoomDeletion = () => {
    setShowInventoryRoomDeleteModal(true);
  };

  const initiatePlantRoomAdd = license => {
    setLicenseToAddTo(license);
    setShowPlantRoomAddModal(true);
  };

  const initiateInventoryRoomAdd = license => {
    setLicenseToAddTo(license);
    setShowInventoryRoomAddModal(true);
  };

  const createDeletionModal = (deleteHandler, flag) => (
    <YesNoModal
      isOpen={flag}
      title="Delete Room?"
      onYes={deleteHandler}
      onNo={hideDeletionModal}
    />
  );

  const editPlantRoom = id => {
    setPlantRoomIdBeingEdited(id);
  };

  const plantRoomEditModal = () =>
    validId(plantRoomIdBeingEdited) ? (
      <ModalEditPlantRoomForm
        room={roomQueries.plantRoomDetails[plantRoomIdBeingEdited]}
        validator={validatePlantRoom}
        saveHandler={plantRoomSaveHandler}
        purposes={roomQueries.purposes}
        onClose={onModalClose}
        deleteHandler={promptForPlantRoomDeletion}
      />
    ) : null;

  const plantRoomAddModal = () =>
    showPlantRoomAddModal ? (
      <ModalAddPlantRoomForm
        license={licenseToAddTo}
        purposes={roomQueries.purposes}
        validator={validatePlantRoom}
        saveHandler={plantRoomSaveHandler}
        onClose={onModalClose}
      />
    ) : null;

  const plantRoomDeletionModal = () =>
    createDeletionModal(deletePlantRoom, showPlantRoomDeleteModal);

  const editInventoryRoom = id => {
    setInventoryRoomIdBeingEdited(id);
  };

  const inventoryRoomEditModal = () =>
    validId(inventoryRoomIdBeingEdited) ? (
      <ModalEditInventoryRoomForm
        room={roomQueries.inventoryRoomDetails[inventoryRoomIdBeingEdited]}
        validator={validateInventoryRoom}
        saveHandler={inventoryRoomSaveHandler}
        onClose={onModalClose}
        deleteHandler={promptForInventoryRoomDeletion}
      />
    ) : null;

  const inventoryRoomAddModal = () =>
    showInventoryRoomAddModal ? (
      <ModalAddInventoryRoomForm
        license={licenseToAddTo}
        validator={validateInventoryRoom}
        saveHandler={inventoryRoomSaveHandler}
        onClose={onModalClose}
        deleteHandler={promptForInventoryRoomDeletion}
      />
    ) : null;

  const inventoryRoomDeletionModal = () =>
    createDeletionModal(deleteInventoryRoom, showInventoryRoomDeleteModal);

  const roomListing = () => (
    <RoomListingView
      stepCompleted={stepCompleted}
      roomsHaveBeenSkippedForAllLicenses={roomsHaveBeenSkippedForAllLicenses}
      plantRoomSaveHandler={plantRoomSaveHandler}
      inventoryRoomSaveHandler={inventoryRoomSaveHandler}
      skippableSectionsBuilders={skippableSectionsBuilders}
      licenses={licenses}
      {...props}
      {...roomQueries}
      {...allQueries}
      onEditPlantRoom={editPlantRoom}
      onEditInventoryRoom={editInventoryRoom}
      onAddPlantRoom={initiatePlantRoomAdd}
      onAddInventoryRoom={initiateInventoryRoomAdd}
    />
  );

  const roomByLicenseWizardView = () => (
    <RoomsByLicenseWizardView
      plantRoomSaveHandler={plantRoomSaveHandler}
      inventoryRoomSaveHandler={inventoryRoomSaveHandler}
      skippableSectionsBuilders={skippableSectionsBuilders}
      licenses={licenses}
      {...props}
      {...allQueries}
      onEditPlantRoom={editPlantRoom}
      onEditInventoryRoom={editInventoryRoom}
    />
  );

  return (
    <div>
      <h1>Add Room Details</h1>
      <WistiaVideo id={roomsStep.intro.videoID} />

      {plantRoomAddModal()}

      {plantRoomEditModal()}

      {inventoryRoomAddModal()}

      {inventoryRoomEditModal()}

      {plantRoomDeletionModal()}

      {inventoryRoomDeletionModal()}

      <InternalStepProgress
        nextStep={props.nextStep}
        stepsConfig={[
          {
            step: 1,
            label: '1. Add Room Details',
            component: roomByLicenseWizardView(),
          },
          { step: 2, label: '2. Confirm Room Details', component: roomListing() },
        ]}
      />
    </div>
  );
};

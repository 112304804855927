import { InventoryRoomForm, PlantRoomForm } from 'components/Forms/RoomForms';
import React, { Fragment } from 'react';
import {
  newInventoryRoom,
  newPlantRoom,
  validateInventoryRoom,
  validatePlantRoom,
} from '../../../utilities/rooms';

import { Grid } from 'semantic-ui-react';
import RoomList from 'components/widgets/RoomList';

export const RoomByLicenseDetailsView = props => {
  const {
    inventoryRoomSaveHandler,
    inventoryRooms,
    license,
    onEditInventoryRoom,
    onEditPlantRoom,
    plantRoomSaveHandler,
    plantRooms,
    purposes,
    skippableInventorySectionBuilder,
    skippablePlantSectionBuilder,
  } = props;

  const plantRoomSection = () =>
    skippablePlantSectionBuilder(
      <Fragment>
        <h3>Understanding Plant Rooms in Traceability</h3>
        <p>
          Think of these as literal rooms or areas at your operation where plants are
          growing. This could be a four-walled room, a hoop house or greenhouse, or even a
          row within a larger room or greenhouse
        </p>

        <p>
          Not only does this tie into Traceability, it helps you understand exactly where
          groups of plants are within your operation.
          <strong>You must add plant rooms on a per-license basis.</strong>
        </p>

        <h2>Plant Rooms for License # {license}</h2>

        <Grid columns={2}>
          <Grid.Column width={11}>
            <PlantRoomForm
              {...props}
              validator={validatePlantRoom}
              submitText="+ Submit Plant Room"
              onSubmit={plantRoomSaveHandler}
              purposes={purposes}
              showCancel={false}
              fieldValues={newPlantRoom(license)}
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <RoomList title="Plant Rooms" rooms={plantRooms} onEdit={onEditPlantRoom} />
          </Grid.Column>
        </Grid>
      </Fragment>
    );

  const inventoryRoomSection = () =>
    skippableInventorySectionBuilder(
      <Fragment>
        <h3>Understanding Inventory Rooms in Traceability</h3>

        <p>
          Rooms are where inventory on it’s way to sale lives. Think anything containing
          cannabis in your operation that is no longer a clone, vegging plant, flowering
          plant or drying plant - this is all inventory and belongs in inventory rooms.
        </p>

        <p>
          Not only does this tie into Traceability, it helps you understand exactly where
          all of your inventory is within your operation.{' '}
          <strong>You must add Inventory rooms on a per-license basis.</strong>
        </p>

        <h2>Inventory Rooms for License # {license}</h2>

        <Grid columns={2}>
          <Grid.Column width={11}>
            <InventoryRoomForm
              {...props}
              fieldValues={newInventoryRoom(license)}
              onSubmit={inventoryRoomSaveHandler}
              showCancel={false}
              validator={validateInventoryRoom}
              submitText="+ Submit Inventory Room"
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <RoomList
              title="Inventory Rooms"
              rooms={inventoryRooms}
              onEdit={onEditInventoryRoom}
            />
          </Grid.Column>
        </Grid>
      </Fragment>
    );

  return (
    <Fragment>
      {plantRoomSection()}

      <br />

      {inventoryRoomSection()}
    </Fragment>
  );
};

import React, { useState } from 'react';

import { Button } from 'semantic-ui-react';
import { RoomByLicenseDetailsView } from './_RoomByLicenseDetailsView';

export const RoomsByLicenseWizardView = props => {
  const { licenses } = props;
  const [itemIndex, setItemIndex] = useState(0);

  const previousLicense = () => setItemIndex(itemIndex - 1);
  const nextLicense = () => setItemIndex(itemIndex + 1);

  const actionsSection = () => (
    <>
      {itemIndex > 0 && (
        <Button onClick={previousLicense}>Back To Previous License</Button>
      )}
      {itemIndex < licenses.length - 1 && (
        <Button onClick={nextLicense}>Continue To Next License</Button>
      )}
      {!props.roomsHaveBeenSkippedForAllLicenses && (
        <Button onClick={props.nextInternalStep}>All Rooms Are Setup</Button>
      )}
      {props.roomsHaveBeenSkippedForAllLicenses && (
        <Button onClick={props.nextStep}>Skip</Button>
      )}
    </>
  );

  const licenseSection = license => (
    <RoomByLicenseDetailsView
      {...props}
      license={license}
      plantRoomsSkipped={props.skippingPlantRoomsForLicense(license)}
      plantRooms={props.allPlantRoomsForLicense(license)}
      inventoryRoomsSkipped={props.skippingInventoryRoomsForLicense(license)}
      inventoryRooms={props.allInventoryRoomsForLicense(license)}
      skippablePlantSectionBuilder={props.skippableSectionsBuilders(license).plantRooms}
      skippableInventorySectionBuilder={
        props.skippableSectionsBuilders(license).inventoryRooms
      }
    />
  );

  return (
    <>
      {licenseSection(licenses[itemIndex])}
      <br />
      <div>{actionsSection()}</div>
    </>
  );
};
